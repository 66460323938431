import React, { useState } from 'react';
import { Grid, Button, TextField, Typography, TextFieldProps } from '@mui/material';
import { nb } from 'date-fns/locale';

interface InputFormProps {
  onClose: () => void;
  boxId: number;
}

const InputForm: React.FC<InputFormProps> = ({ onClose, boxId }) => {
  const [date, setDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [hours, setHours] = useState<string>('');
  const [freeText, setFreeText] = useState<string>('');

  // const setCurrentDate = () => {
  //   setDate(new Date());
  // };

  // const handleChangeDate = (event: React.ChangeEvent<TextFieldProps['onChange']>) => {
  //   setDate(event.target.value);
  // };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={12}>
        <Typography variant="h5">Input Form</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onClose}>Close</Button>
      </Grid>
      <Grid item xs={12}>
        <TextField 
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField 
          label="Timer"
          type="number"
          value={hours}
          onChange={(e) => setHours(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Beskrivelse"
          multiline
          rows={4}
          value={freeText}
          onChange={(e) => setFreeText(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Box ID: {boxId}</Typography>
      </Grid>
    </Grid>
  );
};

export default InputForm;