import React, { useState } from 'react';
import { Button, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import PopupComponent from './PopupComponent';
import MenuItem from '@mui/material/MenuItem';
import { useApi } from '../../../services/HttpService';
//const { api: registerHoursApi, response: registerHoursResponse, isLoading: registerHoursIsLoading, error: registerHoursError } = useApi();
//const { api: fetchProjectsApi, response: fetchProjectsResponse, isLoading: fetchProjectsIsLoading, error: fetchProjectsError } = useApi();

const HourTableComponent: React.FC = () => {
  const [selectedValue, SetSelectedValue] = useState<number>(0);
  const [selected, setSelected] = useState(false);
//   const [dateValue, setDateValue] = useState<string>(new Date().toISOString().split('T')[0]);
  const [dateValue, setDateValue] = useState<string>(new Date().toISOString().split('T')[0]);
  const [hoursValue, setHoursValue] = React.useState<number>(0);
  const [freeTextValue, setFreeTextValue] = React.useState<string>('');
  const [isLoading, setIsloading] = useState(false);
  const [isValid, setIsValid] = useState(false)


  const submit = async (event: any) => {
    setIsloading(true);
    // setDisplayMessage('');
    // setValidated(true);

    let forms = event.currentTarget
    // let form = new FormData(forms);

    event.preventDefault();
    if (isValid){ //forms.checkValidity() === false) {
      //event.stopPropagation();
      setIsloading(false);
      return;
    }else{
      //await registerHoursApi("registerHours", "POST", { hours: hoursValue, projectId: selectedValue, beskrivelse: freeTextValue, date: dateValue });
    }
    
    setIsloading(false);
  }

  
  const saveChanges = () => {

    if (isValid){ //forms.checkValidity() === false) {
        //event.stopPropagation();
        setIsloading(false);
        return;
      }else{
        //await registerHoursApi("registerHours", "POST", { hours: hoursValue, projectId: selectedValue, beskrivelse: freeTextValue, date: dateValue });
      }
    setSelected(false)
    setIsloading(false)
    SetSelectedValue(0)
  };

  const data = [
    { name: 'Administrasjon 2022', value: 12331 },
    { name: 'Teglverksgata 7-9', value: 22235 },
    { name: 'Ryensvingen 5-7', value: 52322 },
    { name: 'Seminar trysil', value: 72134 },
    { name: 'Pensjonsforsikring', value: 156237 },
    // Add more buttons as needed
  ];


  

  return (
    <>
    {selected ? ( 
    <> 
    <form onSubmit={submit}>
        <TextField
            label="Date"
            type="date"
            value={dateValue}
            onChange={(e) => setDateValue(e.target.value)}
            InputLabelProps={{
                shrink: true,
        }}
        />
        <TextField
            label="Timer"
            type="number"
            value={hoursValue}
            onChange={(e) => setHoursValue(Number(e.target.value))}
            InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        />
        <TextField
            label="Beskrivelse"
            value={freeTextValue}
            onChange={(e) => setFreeTextValue(e.target.value)}
        />
        <TextField
            label="Hidden Value"
            value={SetSelectedValue}
            disabled
            style={{ display: 'none' }}
        />
        <Button 
        onClick={saveChanges}
        color="primary"
        variant="contained"
        size="large"
        sx={{ mt: 2, ml: 2 }}
        >
            Lagre
        </Button>
    </form>
    </>

    ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* We'll add buttons here dynamically */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <MenuItem 
              key={item.name} 
              value={item.value}
              onClick={() => {
                setSelected(true);
                SetSelectedValue(item.value);
              }}
              >
                <TableCell>{item.name}</TableCell>
                {/* <Button onClick={() => {
                  setOpenPopup(true);
                  setPopupValue(item.value);
                }}>View Details</Button> */}
              </MenuItem>
            ))}
          </TableBody>
        </Table>
    )}
    </>
  );
};

export default HourTableComponent;