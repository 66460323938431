import { Alert, Box, Button, Checkbox, CircularProgress, Fade, FormControlLabel, IconButton, Paper, SxProps, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';
import React, { ChangeEvent, useState } from "react";
import { DateTimeField } from "@mui/x-date-pickers";
import { useApi } from "../../services/HttpService";
import CloseIcon from '@mui/icons-material/Close';
import { dayjsService } from "../../services/dayjsService";

dayjs.extend(utc);

const modalStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: '10px 10px 20px 0px rgba(0,0,0,0.7)',
  // padding: '10px 0 0 10px',
  overflowY: 'auto',
  maxHeight: '90vh',
};

const headerStyle: SxProps = {
  fontSize: '1.4rem',
  p: 1,
  marginBottom: '1rem',
  borderBottom: '1px solid grey',
};

const contentStyle: SxProps = {
  p: 2,
};

const inputBox: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1,
};

const footerStyle: SxProps = {
  backgroundColor: 'background.paper',
  padding: '10px',
  position: 'sticky',
  bottom: -1, // keep text from klipping at bottom
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 1,
};

// Might need a intermidiate state to track changes for dates. 
// As editing the year of a valid date is buggy
interface editField {
  oldValue: any,
  newValue: any,
  changed: boolean,
}

interface editLabel {
  label: string,
  field: editField,
}

interface editModalProps {
  tableName: string;
  items: any[];
  ids: readonly number[];
  columns: any[];
  columnTypes: any;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export interface changedColumns {
  column: string;
  value: string;
}

/** component that takes in the item and opens a modal,
 * the modal shows a input box for each of the fields of the item,
 * supported inputs are: number, string, date, boolean.
 * if only one item is passed in, the fields will be filled out.
 * else, editiong a field will change all items value.
 */
const EditModal = (props: editModalProps) => {
  const [edits, setEdits] = useState<editLabel[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { api, isLoading, error } = useApi();
  const infoColumns: string[] = ["created_at", "created_by", "updated_at", "updated_by", "deleted_at", "deleted_by", "status", "version"];

  React.useEffect(() => {
    setErrorMessage("")
  }, [props.open])

  React.useEffect(() => {
    setErrorMessage(error)
  }, [error])

  React.useEffect(() => {
    const newEdits: editLabel[] = [];
    if (props.items.length === 1) {
      props.items.forEach((item, indexs) => {
        props.columns.forEach((col) => {
          // console.log("col", col, "item", item, "item[col]", item[col]);
          if (col === "from_date") {
            console.log("from_date:", "item[col]", item[col], "dayjs(item[col])", dayjs(item[col]));
          }
          newEdits.push({ label: col, field: { oldValue: item[col], newValue: item[col], changed: false } });
        })
      })
      setEdits(newEdits);
    } else {
      props.columns.forEach((col) => {
        newEdits.push({ label: col, field: { oldValue: '', newValue: '', changed: false } })
      })
      setEdits(newEdits);
    }

    // console.log("edits", edits);
  }, [props.items, props.columns])

  const handleSubmit = () => {
    let changedColumns: changedColumns[] = [];

    let changes = edits.filter(val => val.field.changed);
    changes.forEach(elem => {
      let value = elem.field.newValue;
      if (props.columnTypes[elem.label] === "Time") {
        // value = toDateString(value);
        value = dayjs(value).format();
      }
      console.log("value", value)
      changedColumns.push({ column: elem.label, value: String(value) })
    })

    if (changedColumns.length === 0) {
      props.onClose();
      return;
    }

    const data = {
      "table_name": props.tableName,
      "ids": props.ids,
      "updates": changedColumns
    };

    console.log("data", data);

    api("/tables/update", "POST", data).then(res => {
      if (res?.statusCode === 200) {
        props.onSuccess();
        handleClose();
      }
    })
  }

  // will not close the modal if a request is being processed.
  const handleClose = () => {
    if (isLoading) {
      return;
    }
    props.onClose();
  }

  const handleOnChanged = (event: any, label: string, type?: string) => {
    let e = [...edits];
    let field = edits.find(x => x.label === label).field;

    if (type === 'int') {
      field.newValue = parseInt(event.target.value, 10);
    } else if (type === 'bool') {
      field.newValue = field.newValue === "1" ? "0" : "1";
    } else if (type === 'Time') {
      let date = dayjs(event);
      // console.log("dateString", dayjsService.toUtcDateString(date), "date format", date.format());
      if (date.isValid() && date.year() > 1900) {
        // field.newValue = toUtcDateString(date);
        field.newValue = date;
      }
    } else {
      field.newValue = event.target.value;
    }

    // set changed
    let newVal = field.newValue;
    let oldVal = field.oldValue;
    if (type === 'Time') {
      const date = dayjs(field.newValue);

      if (date.isValid() && date.year() > 1900) {
        newVal = date.format()
      }
    }

    if (newVal !== oldVal) {
      field.changed = true;
    } else {
      field.changed = false;
    }

    e.find(x => x.label === label).field = field;
    setEdits(e);
  }

  const getInputFieldType = (index, type: string, col, disabled) => {
    let value = edits.find(x => x.label === col)?.field?.newValue;

    if (type === "bool") {
      value = value === "1" ? "1" : "0";
    } else if (type === "Time") {
      value = dayjs(value);

      if (!value.isValid()) {
        value = null;
      }
    }

    return (
      <Box key={index} sx={{
        m: 1,
        display: 'flex',
        justifyContent: 'start',
        width: 220,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        backgroundColor: edits.find(x => x.label === col)?.field?.changed ? 'rgba(0,60,200,0.25)' : 'inherit'
      }}>
        {type.toLowerCase() === 'int' ? ( // Int => null.int, int => int
          <TextField
            label={col}
            variant="standard"
            type="number"
            disabled={disabled}
            sx={{ width: '100%' }}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChanged(event, col, type);
            }}
          />
        ) : (type === 'string') ? (
          <TextField
            id={col}
            label={col}
            variant="standard"
            type="string"
            disabled={disabled}
            sx={{ width: '100%' }}
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleOnChanged(event, col, type);
            }}
          />
        ) : (type === 'bool') ? (
          <FormControlLabel
            label={col}
            sx={{ width: '100%' }}
            control={
              <Checkbox
                checked={value === "1" ? true : false}
                onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  handleOnChanged(event, col, type);
                }}
              />
            }
          />
        ) : (type === 'Time') ? (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DateTimeField
              label={col}
              sx={{ width: '100%' }}
              format="L HH:mm:ss"
              ampm={false}
              disabled={disabled}
              variant="standard"
              value={value}
              onChange={(event) => handleOnChanged(event, col, type)}
            // referenceDate={dayjs('2022-04-17T15:30:27')}
            // defaultValue={dayjs('2022-04-17T15:30')}
            />
          </LocalizationProvider>
        ) : (
          <TextField
            label={col + ' (' + type + ' not supported)'}
            variant="standard"
            type="string"
            sx={{ width: '100%' }}
            disabled={true}
            value={edits.find(x => x.label === col)?.field?.oldValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              // setName(event.target.value);
            }}
          />
        )}
      </Box>
    )
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
    >
      <Fade in={props.open}>
        <Box sx={modalStyle}>
          <Box sx={headerStyle}>
            Edit {props.tableName}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 4,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Loop over all columns and create inputs based on types */}
          <Box sx={contentStyle}>
            {errorMessage && (
              <Box sx={{width: "100%", mb: 2}}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
            {/* Fields that are editable */}
            <Box sx={inputBox}>
              {props.columns.map((col, index) => {
                const type: string = props.columnTypes[col];
                const disabled = col === 'id';
                const isInfoCol = infoColumns.includes(col);

                if (!isInfoCol) {
                  return (
                    getInputFieldType(index, type, col, disabled)
                  )
                }
              })}
            </Box>
            <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
              Info
            </Typography>
            {/* Fields that are not editable */}
            <Box sx={inputBox}>
              {props.columns.map((col, index) => {
                const type = props.columnTypes[col];
                const disabled = true;
                const isInfoCol = infoColumns.includes(col);

                if (isInfoCol) {
                  return (
                    getInputFieldType(index, type, col, disabled)
                  )
                }
              })}
            </Box>
          </Box>
          <Paper elevation={6} sx={footerStyle}>
            <Button
              color="darkgrey"
              type="submit"
              variant="contained"
              size="medium"
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              title="Submit"
              color="primary"
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={22} />}
            >
              Submit
            </Button>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
}

export default EditModal