import React from 'react';
import { Box } from '@mui/material';

interface BoxProps {
  id: number;
  onClick: () => void;
}

const BoxComponent: React.FC<BoxProps> = ({ id, onClick }) => {
  return (
    <Box 
      sx={{ 
        width: '100px',
        height: '100px',
        bgcolor: 'grey.200',
        cursor: 'pointer' 
      }}
      onClick={onClick}
    >
      {id}
    </Box>
  );
};

export default BoxComponent;