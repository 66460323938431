import * as React from 'react';
import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { visuallyHidden } from '@mui/utils';
import { CircularProgress, TableFooter } from '@mui/material';
import EditModal, { changedColumns } from '../modals/EditModal';
import { useApi } from '../../services/HttpService';
import HardDeleteDialog from '../modals/dialog/HardDeleteDialog';
import { dayjsService } from '../../services/dayjsService';
import dayjs from 'dayjs';

// Sorting & selecting table from:
// https://mui.com/material-ui/react-table/

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  type: string;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              minWidth: headCell.type === "string" ? 150 :
                headCell.type === "Time" ? 180 : 100,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  title: string;
  onDelete?: () => void;
  onAdd?: () => void;
  onEdit?: () => void;
  onImport?: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          <Tooltip title="Edit">
            <IconButton color='inherit' onClick={props.onEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" onClick={props.onDelete}>
            <IconButton color='inherit'>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton color='inherit'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const color = theme.palette.mode === "dark" ? "white" : "black";
  const disabledColor = "rgba(0,0,0,0.4)"

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon style={{ color: page === 0 ? disabledColor : color }} /> : <FirstPageIcon style={{ color: page === 0 ? disabledColor : color }} />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight style={{ color: page === 0 ? disabledColor : color }} /> : <KeyboardArrowLeft style={{ color: page === 0 ? disabledColor : color }} />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft style={{ color: page >= Math.ceil(count / rowsPerPage) - 1 ? disabledColor : color }} /> : <KeyboardArrowRight style={{ color: page >= Math.ceil(count / rowsPerPage) - 1 ? disabledColor : color }} />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon style={{ color: page >= Math.ceil(count / rowsPerPage) - 1 ? disabledColor : color }} /> : <LastPageIcon style={{ color: page >= Math.ceil(count / rowsPerPage) - 1 ? disabledColor : color }} />}
      </IconButton>
    </Box>
  );
}

interface Row {
  id: number;
  [key: string]: string | number;
}

interface TableProps {
  tableName: string;
  rows: any[];
  columns: string[];
  columnTypes: any;
  loadingData: boolean;
  addFilter: (filter: string) => void;
  reloadTable: () => void;
}

export const AdminTable = (props: TableProps) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { api, isLoading: deleteIsLoading, error: deleteError } = useApi();
  
  const mappedRows: Row[] = props.rows.map(row => {
    const obj: Row = { id: 0 };
    props.columns.forEach((column, index) => {
      obj[column] = row[index];
    });
    return obj;
  });

  React.useEffect(() => {
    // console.log(props.tableName, "props.rows changed: length ", props.rows.length, "resetting page and selected")
    setPage(0);
    setSelected([]);
  }, [props.rows])

  const visibleRows = React.useMemo(
    () =>
      stableSort(mappedRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, props.rows],
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const idToNumber = (id: number | string) => {
    if (typeof id === 'number') {
      return id;
    } else if (typeof id === 'string' && !isNaN(Number(id))) {
      return Number(id);
    } else {
      throw new Error('ID is not a number or cannot be converted to a number');
    }
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = mappedRows.map((row) => {
        return idToNumber(row.id);
      });
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string | number) => {
    id = idToNumber(id);
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number | string) => {
    id = idToNumber(id);
    return selected.indexOf(id) !== -1;
  }

  const handleOpenEdit = () => {
    let itemsToEdit = [];
    if (selected.length === 1) {
      selected.forEach(id => {
        const item = mappedRows.find(row => row.id === id);
        itemsToEdit.push({ ...item })
      })
    }

    setSelectedItems(itemsToEdit);
    setOpenEdit(true);
  }

  const handleDelete = (hardDelete: boolean) => {
    const data = {
      table_name: props.tableName,
      ids: selected,
      hard_delete: hardDelete,
    }

    api("/tables/delete", "POST", data).then(res => {
      if (res) {
        if (res.statusCode === 200) {
          props.reloadTable();
          setOpenDelete(false);
        }
      }
    })
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const headCells: HeadCell[] = props.columns.map(col => {
    const type: string = props.columnTypes[col]
    return {
      disablePadding: col === "id",
      id: col,
      label: col,
      numeric: type.toLowerCase() === "int",
      type: type,
    }
  })

  return (
    <>
      <EditModal
        tableName={props.tableName}
        items={selectedItems}
        ids={selected}
        columns={props.columns}
        columnTypes={props.columnTypes}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        onSuccess={() => props.reloadTable()}
      />
      <HardDeleteDialog
        open={openDelete}
        bodyText={`Are you sure you want to delete ${selected.length} item${selected.length > 1 ? "s" : ""}? Click "DELETE" to confirm.`}
        includeHardDelete={true}
        loading={deleteIsLoading}
        errorMessage={deleteError}
        onClose={() => setOpenDelete(false)}
        onConfirm={(hardDelete) => handleDelete(hardDelete)}
      />

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, p: 1 }}>
          <Paper elevation={3}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              title={props.tableName}
              onEdit={handleOpenEdit}
              onDelete={() => setOpenDelete(true)}
            />
          </Paper>

          <TableContainer sx={{ minWidth: 400, overflow: 'auto' }}>
            {props.loadingData ? (
              <>
                <Box sx={{ width: '100%', minHeight: "400px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress color="info" />
                </Box>
              </>
            ) : visibleRows.length === 0 ? (
              <Box sx={{ width: '100%', minHeight: "400px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6'>
                  {props.tableName === "" ? "Ingen tabell valgt" : "Ingen data å vise"}
                </Typography>
              </Box>
            ) : (
              <>
                <Table
                  sx={{ width: '100%', minHeight: "400px", }}
                  aria-labelledby="admin-table"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={props.rows.length}
                    headCells={headCells}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>

                          {Object.entries(row).map(([key, value]) => {
                            let cell = headCells.find(cell => cell.label === key);
                            if (cell?.type === "bool") {
                              value = value === "1" ? "true" : "false";
                            } else if (cell?.type === "Time") {
                              const date = dayjs(value);
                              value = date.isValid() ? date.format("DD.MM.YYYY HH:mm:ss") : null;
                            }
                            return (
                              <TableCell key={key} align={cell?.numeric ? "right" : "left"}>{value}</TableCell>
                            )
                          })
                          }
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
            count={props.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </>
  );
}