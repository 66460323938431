import { authStore } from "../App";
import localStorageService, { LocalStorageKeys } from "./LocalStorageService";
import { getValidToken, useApi } from "./HttpService";



export const AuthService = () => {
  const { api } = useApi();

  const login = async (token: any): Promise<boolean> => {
    localStorageService.setItem(LocalStorageKeys.OIDC_TOKEN, token);

    return true;
  }

  const logout = async (): Promise<boolean> => {
    let token = localStorageService.getItem(LocalStorageKeys.OIDC_TOKEN);
    if (token) {
      fetch("/api/logout", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    }

    localStorageService.removeItem(LocalStorageKeys.OIDC_TOKEN);
    authStore.setState({ isLoggedin: false, userRoles: [], userName: '' });

    return true;
  }

  const authenticate = async (): Promise<boolean> => {
    // let token = localStorageService.getItem(LocalStorageKeys.OIDC_TOKEN);
    let token = await getValidToken(false);

    if (!token) {
      return false;
    }

    const response = await fetch("/api/me", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response?.ok) {
      let res = await response.json();
      authStore.setState({ isLoggedin: true, userRoles: res.roles, userName: res.contact.first_name });
      return true;
    }

    return false;
  }

  return { login, logout, authenticate }
};
