import { Alert, Box, CircularProgress, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { InfoArea } from '../../components/ui/InfoArea';
import { useApi } from '../../services/HttpService';

interface FieldInfo {
  name: string;
  datatype: string;
  auto_increment: boolean;
  allow_null: boolean;
  default_value: string;
  foreign_key: string;
  primary_key: boolean;
}

interface ForeignKeyInfo {
  table_name: string;
  column_name: string;
  constraint_name: string;
  referenced_table_name: string;
  referenced_column_name: string;
}

interface WrongForeignKeyInfo {
  database_foreign_key: ForeignKeyInfo;
  sys_foreign_key: ForeignKeyInfo;
}

interface WrongTabelFields {
  database_field: FieldInfo;
  sys_field: FieldInfo;
}

interface TabelFields {
  [table_name: string]: FieldInfo[];
}

interface WrongTabel {
  [table_name: string]: WrongTabelFields[];
}

interface DatabaseState {
  missing_tables: string[];
  extra_tables: string[];
  missing_fields: TabelFields;
  extra_fields: TabelFields;
  wrong_fields: WrongTabel;
  missing_foreign_key: ForeignKeyInfo[];
  extra_foreign_key: ForeignKeyInfo[];
  wrong_foreign_key: WrongForeignKeyInfo[];
  missing_foreign_key_not_found: string[];
  duplicate_foreign_keys: ForeignKeyInfo[];
}

interface TableAreaProps {
  tableName: string;
  rows: FieldInfo[];
}

export function TableArea(props: TableAreaProps) {
  const { tableName, rows } = props;

  return (
    <Box>
      <Typography variant='h6'>{tableName}</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} size="small" aria-label="database states">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Datatype</TableCell>
              <TableCell align="right">Auto inc.</TableCell>
              <TableCell align="right">Allow null</TableCell>
              <TableCell>Default</TableCell>
              <TableCell align="right">FK</TableCell>
              <TableCell align="right">PK</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.datatype}</TableCell>
                <TableCell align="right">{row.auto_increment ? "true" : "false"}</TableCell>
                <TableCell align="right">{row.allow_null ? "true" : "false"}</TableCell>
                <TableCell>{row.default_value}</TableCell>
                <TableCell align="right">{row.foreign_key ? "true" : "false"}</TableCell>
                <TableCell align="right">{row.primary_key ? "true" : "false"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

function ForeignKeyTableArea(props: { rows: ForeignKeyInfo[] }) {
  const { rows } = props;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} size="small" aria-label="database states">
          <TableHead>
            <TableRow>
              <TableCell>Table</TableCell>
              <TableCell>Column</TableCell>
              <TableCell>Constraint</TableCell>
              <TableCell>Ref. Table</TableCell>
              <TableCell>Ref. Column</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.constraint_name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.table_name}</TableCell>
                <TableCell>{row.column_name}</TableCell>
                <TableCell>{row.constraint_name}</TableCell>
                <TableCell>{row.referenced_table_name}</TableCell>
                <TableCell>{row.referenced_column_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export const DatabaseState = () => {
  const { api, error, isLoading } = useApi();
  const [databaseState, setDatabaseState] = useState<DatabaseState>({} as DatabaseState);

  useEffect(() => {
    api("/databaseState").then(res => {
      if (res?.statusCode === 200) {
        setDatabaseState(res);
      }
    });
  }, [])

  useEffect(() => {
    // console.log("databaseState:", databaseState.extra_tables)
  }, [databaseState])

  return (
    <>
      <Typography variant='h4' sx={{ mt: 2 }}>Database Status</Typography>
      {isLoading ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="info" />
        </Box>
      ) : error ? (
        <Alert sx={{ width: { xs: '100%', md: 768 } }} severity='error'>{error}</Alert>
      ) : (
        <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
          <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, width: '100%', maxWidth: { xs: '100%', md: 768 }, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 2 }}>
            <Typography variant='h5' sx={{ mt: 2 }}>Field Statuses</Typography>
            <InfoArea title="Table states">
              {(databaseState?.missing_tables?.length > 0 || databaseState?.extra_tables?.length > 0) ? (
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, flexWrap: "wrap" }}>
                  <Box sx={{ p: 2, width: { xs: "100%", md: 340 }, border: "1px solid black" }}>
                    <Typography variant='h6' sx={{ mt: 2 }}>Missing tables:</Typography>
                    <List dense>
                      {databaseState?.missing_tables?.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={item}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ p: 2, width: { xs: "100%", md: 340 }, border: "1px solid black" }}>
                    <Typography variant='h6' sx={{ mt: 2 }}>Extra tables:</Typography>
                    <List dense>
                      {databaseState?.extra_tables?.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={item}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              ) : (
                <Alert>Tables up to date</Alert>
              )}
            </InfoArea>
            <InfoArea title="Missing fields">
              {databaseState?.missing_fields && Object.keys(databaseState?.missing_fields)?.length > 0 ? (
                <>
                  {Object.keys(databaseState?.missing_fields).map((tableName) => (
                    <TableArea key={tableName} tableName={tableName} rows={databaseState.missing_fields[tableName]} />
                  ))}
                </>
              ) : (
                <Alert>No missing fields</Alert>
              )}
            </InfoArea>
            <InfoArea title="Extra fields">
              {databaseState?.extra_fields && Object.keys(databaseState?.extra_fields).length > 0 ? (
                <>
                  {Object.keys(databaseState?.extra_fields).map((tableName) => (
                    <TableArea key={tableName} tableName={tableName} rows={databaseState.extra_fields[tableName]} />
                  ))}
                </>
              ) : (
                <Alert>No extra fields</Alert>
              )}
            </InfoArea>
            <InfoArea title="Wrong fields">
              {databaseState?.wrong_fields && Object.keys(databaseState?.wrong_fields).length > 0 ? (
                <>
                  {Object.keys(databaseState?.wrong_fields).map((tableName) => (
                    <Box key={tableName}>
                      <Typography variant='h6'>{tableName}</Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ width: "100%" }} size="small" aria-label="database states">
                          <TableHead>
                            <TableRow key={tableName}>
                              <TableCell></TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Datatype</TableCell>
                              <TableCell align="right">Auto inc.</TableCell>
                              <TableCell align="right">Allow null</TableCell>
                              <TableCell>Default</TableCell>
                              <TableCell align="right">FK</TableCell>
                              <TableCell align="right">PK</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {databaseState.wrong_fields[tableName].map((field, index) => (
                              <>
                                <TableRow
                                  key={"have_" + field.database_field.name}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { pt: index > 0 ? 4 : 1, alignContent: "end" } }}
                                >
                                  <TableCell>Database</TableCell>
                                  <TableCell>{field.database_field.name}</TableCell>
                                  <TableCell>{field.database_field.datatype}</TableCell>
                                  <TableCell align="right">{field.database_field.auto_increment ? "true" : "false"}</TableCell>
                                  <TableCell align="right">{field.database_field.allow_null ? "true" : "false"}</TableCell>
                                  <TableCell>{field.database_field.default_value}</TableCell>
                                  <TableCell align="right">{field.database_field.foreign_key ? "true" : "false"}</TableCell>
                                  <TableCell align="right">{field.database_field.primary_key ? "true" : "false"}</TableCell>
                                </TableRow>
                                <TableRow
                                  key={"want_" + field.sys_field.name}
                                  sx={{ '& td': { border: 0 } }}
                                >
                                  <TableCell>System</TableCell>
                                  <TableCell>{field.sys_field.name}</TableCell>
                                  <TableCell>{field.sys_field.datatype}</TableCell>
                                  <TableCell align="right">{field.sys_field.auto_increment ? "true" : "false"}</TableCell>
                                  <TableCell align="right">{field.sys_field.allow_null ? "true" : "false"}</TableCell>
                                  <TableCell>{field.sys_field.default_value}</TableCell>
                                  <TableCell align="right">{field.sys_field.foreign_key ? "true" : "false"}</TableCell>
                                  <TableCell align="right">{field.sys_field.primary_key ? "true" : "false"}</TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ))}
                </>
              ) : (
                <Alert>No wrong fields</Alert>
              )}
            </InfoArea>
          </Paper>
          <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, width: '100%', maxWidth: { xs: '100%', md: 768 }, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 2 }}>
            <Typography variant='h5' sx={{ mt: 2 }}>Foreign Key Statuses</Typography>
            {databaseState?.missing_foreign_key_not_found && databaseState?.missing_foreign_key_not_found?.length > 0 && (
              <>
                {databaseState?.missing_foreign_key_not_found.map(message => (
                  <Alert sx={{ width: '100%' }} severity='error'>{message}</Alert>
                ))}
              </>
            )}
            <InfoArea title="Missing keys">
              {databaseState?.missing_foreign_key && databaseState?.missing_foreign_key?.length > 0 ? (
                <ForeignKeyTableArea rows={databaseState.missing_foreign_key} />
              ) : (
                <Alert>No missing keys</Alert>
              )}
            </InfoArea>
            <InfoArea title="Extra keys">
              {databaseState?.extra_foreign_key && databaseState?.extra_foreign_key?.length > 0 ? (
                <ForeignKeyTableArea rows={databaseState.extra_foreign_key} />
              ) : (
                <Alert>No extra keys</Alert>
              )}
            </InfoArea>
            <InfoArea title="Duplicate keys">
              {databaseState?.duplicate_foreign_keys && databaseState?.duplicate_foreign_keys?.length > 0 ? (
                <ForeignKeyTableArea rows={databaseState.duplicate_foreign_keys} />
              ) : (
                <Alert>No duplicate keys</Alert>
              )}
            </InfoArea>
            <InfoArea title="Wrong keys">
              {databaseState?.duplicate_foreign_keys && databaseState?.duplicate_foreign_keys?.length > 0 ? (
                <>
                  {databaseState.wrong_foreign_key.map((wfk, index) => {
                    <TableContainer component={Paper}>
                      <Table sx={{ width: "100%" }} size="small" aria-label="database states">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Table</TableCell>
                            <TableCell>Column</TableCell>
                            <TableCell>Constraint</TableCell>
                            <TableCell>Ref. Table</TableCell>
                            <TableCell>Ref. Column</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            key={"have_" + wfk.database_foreign_key.constraint_name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { pt: index > 0 ? 4 : 1, alignContent: "end" } }}
                          >
                            <TableCell>Database</TableCell>
                            <TableCell>{wfk.database_foreign_key.table_name}</TableCell>
                            <TableCell>{wfk.database_foreign_key.column_name}</TableCell>
                            <TableCell>{wfk.database_foreign_key.constraint_name}</TableCell>
                            <TableCell>{wfk.database_foreign_key.referenced_table_name}</TableCell>
                            <TableCell>{wfk.database_foreign_key.referenced_column_name}</TableCell>
                          </TableRow>
                          <TableRow
                            key={"want_" + wfk.sys_foreign_key.constraint_name}
                            sx={{ '& td': { border: 0 } }}
                          >
                            <TableCell>System</TableCell>
                            <TableCell>{wfk.sys_foreign_key.table_name}</TableCell>
                            <TableCell>{wfk.sys_foreign_key.column_name}</TableCell>
                            <TableCell>{wfk.sys_foreign_key.constraint_name}</TableCell>
                            <TableCell>{wfk.sys_foreign_key.referenced_table_name}</TableCell>
                            <TableCell>{wfk.sys_foreign_key.referenced_column_name}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  })}
                </>
              ) : (
                <Alert>No wrong keys</Alert>
              )}
            </InfoArea>
          </Paper>
        </Box>
      )}
    </>

  )
}