import React, { useState } from 'react';
import { Alert, Box, CircularProgress, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { Grid, Button } from '@mui/material';
import BoxComponent from './components/BoxComponent';
import InputForm from './components/InputForm';

export const HourRegistration = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedBoxId, setSelectedBoxId] = useState<number | null>(null);

  const boxes = Array.from({ length: 5 }, (_, i) => i + 1);

  return (
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {boxes.map((boxId) => (
            <Grid item xs={12} key={boxId}>
              <BoxComponent 
                id={boxId}
                onClick={() => {
                  setShowForm(true);
                  setSelectedBoxId(boxId);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {showForm && selectedBoxId !== null && (
        <InputForm 
          onClose={() => setShowForm(false)} 
          boxId={selectedBoxId}
        />
      )}
    </Grid>
  );
};

// export default HourRegistration;