import React, { useState, useRef, useEffect } from 'react';
import { Alert, AlertColor, CircularProgress, Link, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { Box, useTheme } from '@mui/material';
import localStorageService, { LocalStorageKeys } from '../../services/LocalStorageService';
import { AuthService } from '../../services/AuthService';
import { authStore } from '../../App';

const sleep = ms => new Promise(r => setTimeout(r, ms));

export const OidcCallback = () => {
  const [isloading, setIsloading] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { login, logout, authenticate } = AuthService();

  useEffect(() => {
    const getToken = async () => {
      // Parse the URL to get the access_token
      const params = new URLSearchParams(window.location.search);
      const token = params.get("access_token");

      if (token) {
        await login(token);

        authenticate().then(success => {
          if (!success) {
            logout();
            navigate("/", { replace: true });
          } else {
            // window.location.replace("/home");
            navigate("/home", { replace: true });
          }
          authStore.setState({ initialLoad: true });
        });

      } else {
        setMessage("Access token not found in response");
      }
      setIsloading(false);
    }

    getToken();
  }, [navigate]);

  return (
    <>
      <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {isloading ? (
          <CircularProgress color="info" />
        ) : (
          <>
            {message && (
              <Box sx={{ width: 400, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Alert severity='error'>{message}</Alert>
                <Link href="/">Tilbake</Link>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  )
}