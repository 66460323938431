import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { authStore } from "../App";
import { NotFound, SaleryStatistics } from "../pages";
import { MemberStatistic } from "../pages/public/statistic/member/MemberStatistic";
import { PersonalData } from "../pages";
import { OidcLogin } from "../pages";
import { PosterPoster } from "../pages/public/poster/Poster";
import { PosterNewsletter } from "../pages/public/poster/Newsletter";
import { PosterTemplate } from "../pages/public/poster/Template";
import { DcpAuthProvider } from "../contexts/DcpAuthContext";
import CreatePage from "../pages/public/poster/CreatePage";
import Edit from "../pages/public/poster/Edit";
import CreateTemplate from "../pages/public/poster/CreateTemplate";
import { WorkingCondition } from "../pages";
import { OidcCallback } from "../pages/public/OidcCallback";
import { HourRegistration } from "../pages/osloentrep/HourRegistration";
import { HourOverview } from "../pages/osloentrep/HourOverview";

type Status = "checking" | "authenticated" | "not-authenticated";
let status: Status = "not-authenticated";
// let status: Status = 'authenticated'

export const AppRouter = () => {
  const { isLoggedin, initialLoad, userRoles } = authStore();
  if (!initialLoad) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/** Either display only routes that are available when logged in, or public routes */}
        {isLoggedin ? (
          <Route path="/*" element={<AuthenticatedRoutes />} />
        ) : (
          <Route path="/*" element={<PublicRoutes />} />
        )}

        {/** Routes that should be available all the time */}
        <Route path="/HourRegistration/*" element={<HourRegistration />} />
        <Route path="/HourOverview/*" element={<HourOverview />} />
        <Route path="/oidcLogin/*" element={<OidcLogin />} />
        <Route path="/callback" element={<OidcCallback />} />
        <Route path="/personaldata/*" element={<PersonalData />} />
        <Route path="/personaldata/:uid" element={<PersonalData />} />
        <Route path="/workingCondition/*" element={<WorkingCondition />} />
        <Route path="/workingCondition/:uid" element={<WorkingCondition />} />
        <Route path="/salerystatistics/*" element={<SaleryStatistics />} />
        <Route path="/salerystatistics/:uid" element={<SaleryStatistics />} />
        <Route path="/membersalerystatistics/:uid" element={<MemberStatistic />} />

        {/** Poster routes */}
        <Route path="/poster/*" element={<PosterRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

const PosterRoutes = () => {
  return (
    <DcpAuthProvider>
      <Routes>
        <Route path="/poster/:uid" element={<PosterPoster />} />{" "}
        {/** [url]/poster/poster/:uid */}
        <Route path="/newsletter/:uid" element={<PosterNewsletter />} />
        <Route path="/template/:uid" element={<PosterTemplate />} />
        <Route path="/create-template/:pageType/:title" element={<CreateTemplate />} />
        <Route path="/create/:pageType/:title/:templateID" element={<CreatePage />} />
        <Route path="/edit/:type/:id" element={<Edit />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </DcpAuthProvider>
  );
};
