import React, { useEffect } from "react";
import { AppRouter } from "./routes/AppRouter";
import BaseLayout from "./components/layouts/BaseLayout";
import { create } from "zustand";
import "./App.css";
import "./pages/public/poster/components/i18n";
import { AuthService } from "./services/AuthService";
import { getValidToken, useApi } from "./services/HttpService";
import { themeStore } from "./components/themes/BaseTheme";
import { createMenuitemHirarchy, Menuitem } from "./model/System";

export const authStore = create<{
  isLoggedin: boolean;
  initialLoad: boolean;
  userName: string;
  userRoles: string[];
  routes: Menuitem[];
}>((setState, getState) => ({
  isLoggedin: false,
  initialLoad: false,
  userName: "",
  userRoles: [],
  routes: [],
}));

export const tenantStore = create<{
  name: string;
  displayName: string;
}>((setState, getState) => ({
  name: "",
  displayName: "",
}));

function App() {
  const { isLoggedin } = authStore();
  const { authenticate, logout } = AuthService();
  const { api } = useApi();

  useEffect(() => {
    authenticate().then(success => {
      if (!success) {
        logout();
        authStore.setState({ initialLoad: true });
      }
    });
  }, []);

  useEffect(() => {
    const getMenuItems = async () => {
      console.log("getting menuitme from app");
      let token = await getValidToken(false);

      // asume authenticated if token, get token use fetch
      if (!token) {
        return;
      }

      const response = await fetch("/api/menuItems", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.ok) {
        let res = await response.json();
        const routes = createMenuitemHirarchy(res.routes);
        authStore.setState({ routes: routes, initialLoad: true });
      } else {
        authStore.setState({ routes: [], initialLoad: true });
      }
    }

    getMenuItems();
  }, [isLoggedin]);

  useEffect(() => {
    api("/getInfo", "GET").then((res) => {
      if (res?.statusCode === 200) {
        let pal = res.palette;
        themeStore.getState().updateColors(pal.primary, pal.secondary, pal.success);
        tenantStore.setState({ name: res.name, displayName: res.displayName });
      }
    })
  }, []);

  return (
    <BaseLayout>
      <AppRouter />
    </BaseLayout>
  );
}

export default App;
