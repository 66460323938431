import React, { useState, useCallback } from "react";
import localStorageService, { LocalStorageKeys } from "./LocalStorageService";

let refreshingPromise = null; // Shared promise for the token refresh process

export const getValidToken = async (redirect: boolean) => {
  const token = localStorageService.getItem(LocalStorageKeys.OIDC_TOKEN);
  console.log("token in getValidToken:", token);

  if (isTokenExpired(token)) {
    // If the token is expired and no refresh is in progress
    if (!refreshingPromise) {
      try {
        refreshingPromise = refreshToken(token, redirect);
        const newToken = await refreshingPromise;
        refreshingPromise = null;
        return newToken;
      } catch (error) {
        refreshingPromise = null;
      } finally {
        refreshingPromise = null;
      }
    }

    // If a refresh is already in progress, wait for it to complete
    return refreshingPromise;
  }

  return token;
};

const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return Date.now() >= payload.exp * 1000;
  } catch (error) {
    console.error("token parse exception:", error);
  }
  return true;
};

const refreshToken = async (token: string, redirect: boolean) => {
  try {
    const response = await fetch("/api/refresh-token", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return;
    }
    if (response.redirected && redirect) {
      window.location.href = response.url;
      return;
    }
    if (response.headers.has("New-Access-Token")) {
      let newAccessToken = response.headers.get("New-Access-Token");
      localStorageService.setItem(LocalStorageKeys.OIDC_TOKEN, newAccessToken);

      return newAccessToken;
    }
  } catch (error) {
    console.error("Token refresh failed:", error);
  }
};

/**
 * Generic function for handeling common api calls.
 * Handels common server error responses (soon)
 */
export const useApi = () => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>();

  const api = async (path: string, method: string = "GET", body?: object): Promise<any> => {
    setIsLoading(true);
    setError('');

    let start = Date.now();
    const token = await getValidToken(true);
    console.log("got token fro", path, "took", Date.now()-start)

    if (!path.startsWith("/api/")) {
      const trimmedPath = path.split('/').filter(part => part !== '').join('/');
      path = `/api/${trimmedPath}`;
    }

    try {
      const options: RequestInit = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (method !== "GET" && body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(path, options);
      const json = await response.json();
      const statusCode = response.status ?? 0;
      json.statusCode = statusCode;

      if (!response.ok) {
        let message = json?.error?.message ? json.error.message : "Status: " + response.status;
        setError(message);
      } else {
        setResponse(json);
      }

      setIsLoading(false);
      return json;
    } catch {
      setError("Noe gikk galt, prøv igjen senere.");
      setIsLoading(false);
      const json: any = {
        error: "Noe gikk galt, prøv igjen senere.",
        statusCode: 500,
      }
      return json;
    }
  };

  return { api, response, isLoading, error };
};

export const useMultipartApi = () => {
  const [mError, setError] = useState<string>("");
  const [mIsLoading, setIsLoading] = useState<boolean>(false);
  const [mResponse, setResponse] = useState<any>();

  const multipartApi = async (path: string, formData: FormData): Promise<any> => {
    setIsLoading(true);
    setError('');

    const token = await getValidToken(true);

    if (!path.startsWith("/api/")) {
      const trimmedPath = path.split('/').filter(part => part !== '').join('/');
      path = `/api/${trimmedPath}`;
    }

    try {
      const options: RequestInit = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      options.body = formData;

      const response = await fetch(path, options);
      const json = await response.json();
      const statusCode = response.status ?? 0;
      json.statusCode = statusCode;

      if (!response.ok) {
        let message = json?.error?.message ? json.error.message : "Status: " + response.status;
        setError(message);
      } else {
        setResponse(json);
      }

      setIsLoading(false);
      return json;
    } catch {
      setError("Noe gikk galt, prøv igjen senere.");
      setIsLoading(false);
      const json: any = {
        error: "Noe gikk galt, prøv igjen senere.",
        statusCode: 500,
      }
      return json;
    }
  };

  return { multipartApi, mResponse, mIsLoading, mError };
};
