import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Alert, AlertColor, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import '../../styles/Login.css';
import Button from '@mui/material/Button';
import FallbackImage from '../../components/file/FallbackImage';

export const Login = () => {
  const [isloading, setIsloading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState<AlertColor | undefined>('error');
  const navigate = useNavigate();

  const setDisplayMessage = (message: string, type: AlertColor = 'error') => {
    setMessage(message);
    setMessageType(type);
  }

  const submit = async () => {
    setIsloading(true);
    setDisplayMessage('');

    navigate('/oidcLogin');

    setIsloading(false);
  }

  return (
    <>
      <div id="login" style={{ margin: 'auto' }}>
        <div className="login_left"></div>
        <div className='logo_image'>
          <FallbackImage
            primarySrc="/api/static/logo"
            secondarySrc="/default/logo.png"
            altText="logo"
          />
        </div>
        <div className="login_right">
          {message && <div><br /><Alert severity={messageType}>{message}</Alert></div>}
          <div className="login">
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size="large"
              sx={{
                textTransform: 'none',
                fontSize: '1.5rem',
                fontFamily: "SourceHanSansCN-Regular",
                width: "100%",
                mt: 3,
                mb: 3,
              }}
              disabled={isloading}
              endIcon={isloading && <CircularProgress />}
              onClick={submit}
            >
              Logg Inn
            </Button>
            <Button
              color="darkgrey"
              type="submit"
              variant="contained"
              size="large"
              sx={{
                textTransform: 'none',
                fontSize: '1.5rem',
                fontFamily: "SourceHanSansCN-Regular",
                width: "100%",
              }}
              disabled={isloading}
              endIcon={isloading && <CircularProgress />}
              onClick={submit}
            >
              Registrer
            </Button>
          </div>
        </div>
      </div >
    </>
  )
}